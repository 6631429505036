.shimmerWrapper {
    background-color: #f6f7f8;
    background-image: linear-gradient(
      90deg,
      #f6f7f8 0px,
      #eaeaea 40px,
      #f6f7f8 100px
    );
    background-size: 200% 100%;
    animation: shimmer 3s infinite linear;
  }
  
  @keyframes shimmer {
    0% {
      background-position: -100% 0;
    }
    100% {
      background-position: 100% 0;
    }
  }
  

  /* Fullscreen overlay */
.overlay {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 75vh;
  background:inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* Custom spinner animation */
.spinner {
  width: 4rem;
  height: 4rem;
  border: 5px solid rgba(0, 0, 0, 0.7); /* Light outer ring */
  border-top: 10px solid rgb(248, 245, 245);; 
  border-bottom: 10px solid rgb(248, 245, 245);
  border-radius: 50%; /* Make it circular */
  animation: spin 1.5s linear infinite;
}

/* Spin animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
