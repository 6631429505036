.createPostProfileImg {
  width: 80px;
  margin-bottom: none;
}

.textarea {
  border-color: #ced4da;
  transition: height 0.3s ease;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

@media (max-width: 992px) {
  .createPostProfileImg {
    width: 40px;
    margin-bottom: 2rem !important;
  }

  .textarea {
    font-size: 0.9rem !important;
  }
}

.totalVotes {
  font-size: 14px;
  color: #7f8c8d;
}

.pollResultNote {
  margin-top: 20px;
  margin-left: 0.5rem;
  font-size: 14px;
  color: #464a4d;
  font-style: italic;
  text-align: left;
}

.suggestionBox {
  position: absolute;
  width: 20%;
  background-color: #fff;
  border: 1px solid #ced4da;
  z-index: 100;
  max-height: 200px;
  overflow-y: scroll;
}

.postP {
  cursor: pointer;
  padding: 0.32rem;
}

.previewArea {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.8rem;
}

.previewItem {
  position: relative;
  width: 100px;
  height: 100px;
}

.previewItem img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.25rem;
}

.previewItem button {
  position: absolute;
  top: -0rem;
  right: -0.1rem;
  background: #000;
  color: #fff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 0.2rem 0.3rem;
  font-size: 0.9rem;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.postIconWrapper {
  display: flex;
  justify-content: space-between;
}
.postIconLeft {
  display: flex;
  gap: 6px;
}

.bullishButton {
  font-size: 0.7rem !important;
  background-color: #fff !important;
  gap: 0.3rem !important;
  color: green !important;
  border-radius: 5px !important;
  padding: 0.1rem 1rem !important;
  margin-right: 0.2rem !important;
  border: 1px solid green !important;
  height: 1.3rem !important;
}

.bullishButton:hover {
  background-color: green !important;
  color: #fff !important;
  border: 1px solid green !important;
}

.bullish {
  background-color: green !important;
  color: #fff !important;
  border: 1px solid green !important;
}

.bearishButton {
  font-size: 0.7rem !important;
  font-weight: 500 !important;
  background-color: #fff !important;
  gap: 0.3rem !important;
  height: 1.3rem !important;
  color: red !important;
  border-radius: 5px !important;
  padding: 0.1rem 1rem !important;
  margin-right: 0.2rem !important;
  border: 1px solid red !important;
}

.bearishButton:hover {
  background-color: red !important;
  color: #fff !important;
  border: 1px solid #fff !important;
}
.bearish {
  background-color: red !important;
  color: #fff !important;
  border: 1px solid #fff !important;
}

.postButtonWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center !important;
}

.postButton {
  align-self: center !important;
  text-decoration: none !important;
  background-color: #ffffff !important;
  color: #333 !important;
  padding: 0.2rem 0.6rem !important;
  font-size: 0.8rem !important;
  border-radius: 0.25rem !important;
  margin-left: 0.6rem;
  border: #000 !important;
  border: 1px solid #666 !important;
  border-width: 1px 2px 2px 1px !important;
  font-weight: 400 !important;
}
.postButton:hover {
  background: #000 !important;
  color: #fff !important;
}

.creatPostIcon {
  cursor: pointer;
  color: #555;
}

.creatPostIcon:hover {
  color: #222;
}


.commentButton {
  align-self:center !important;
  text-decoration: none !important;
  background-color: #fff !important;
  color: #333 !important;
  margin-left: 1rem;
  /* margin-top: 0.8rem; */
  padding: 0.2rem 0.6rem;
  font-size: 0.8rem;
  border-radius: 0.25rem;
  border: 1px solid #666 !important;
  border-width: 1px 2px 2px 1px;
  font-weight: 400;
}

.commentButton:hover {
  background: #000;
  color: #fff;
}


.tabsContainer {
  display: flex;
  /* margin-bottom: 1rem; */
  padding-left: 0.8rem;
}

.tabButton {
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-weight: bold;
  background-color: transparent;
  border: none;
  margin-right: 1rem;
  transition: background-color 0.3s;
}

.tabButton:hover {
  background-color: #e0e0e0;
}

.tabButton.active {
  border-color: #ddd;
  border-bottom: 2px solid #007bff;
}

.tabContent {
  padding: 0.5rem;
  border-top: none;
}

.tabPane {
  display: none;
}

.tabPane.active {
  display: block;
}

.feedFollowingWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 2rem;
  padding: 0.1rem;
}

.feedProfileWrapper {
  flex: 1 1 auto; /* Allows the profile wrapper to occupy full width when needed */
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  margin-bottom: 0.2rem;
}

.feedName {
  display: block;
  font-weight: 500;
  font-size: 1rem;
}

.feedTimestamp {
  font-size: 0.8rem;
  color: #6c757d; /* Subtle color for timestamp */
  margin-top: 0.2rem;
}

.feedUsername {
  font-size: 0.85rem;
  font-weight: 400;
  color: #6c757d;
  margin-bottom: 0.2rem;
}

.feedSentiment {
  font-weight: 600;
}

.separator {
  border-top: 1px solid #dfdfdf;
  width: 100%;
  background-color: #d1141407;
  margin-bottom: 1rem;
}

.leftBar {
  padding: 1rem;

  height: 100vh;
  display: flex;
  flex-direction: column;
  margin-top: -1rem;
}

.leftOptionHeader {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 1rem;
  border-bottom: 1px solid #ccc;
  padding-bottom: 0.5rem;
}

.leftOption {
  font-size: 1.2rem;
  color: #555;
  padding: 0.5rem 1rem;
  display: flex;
  cursor: pointer;
  gap: 0px;
  text-decoration: none;
}

.leftOption:hover {
  background-color: #e0e0e0;
  color: #000;
}

.leftOption:active {
  background-color: #d4d4d4;
  color: #333;
}

.modelHeader {
  display: flex;
}

.profileImage {
  margin-right: 1rem;
}

.modal {
  margin-top: 4rem;
}

.feedReactionIconGroup {
  display: flex;
  margin: 1.2rem 0 0.5rem 0;
  text-align: center;
  justify-content: space-between;
  cursor: pointer;
}
.commentReactionIconGroup {
  display: flex;
  margin: 1.2rem 0 0.5rem 0;
  text-align: center;
  justify-content: flex-start;
  cursor: pointer;
}

.feedViewIcon {
  width: 1.8rem;
  height: 1.2rem;
  fill: #555;
}

.reactionIconWrapper {
  font-size: 0.9rem;
  color: #555;
  margin-right: 1.2rem;
  display: flex;
  margin-bottom: 0.5rem;
}
.feedCommentIcon {
  transition: color 0.3s ease;
  margin-right: 0.3rem;
}

.feedShareICon {
}

.feedCardImg {
  min-width: 12rem;
  max-width: 30rem;
}

.pollContainer {
  margin: auto;
  border: none;
  border-radius: 12px;
  width: 100%;
}

.pollExpired {
  margin: auto;
  border: none;
  border-radius: 12px;
  width: 100%;
  opacity: 0.7;
  
}

.pollQuestion {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #333;
}

.expiredTag {
  color: #b0bec5;
  font-size: 14px;
  margin-left: 5px;
}

.pollOptions {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.pollOption {
  width: 80%;
  padding: 12px 16px;
  border: 1px solid #ddd;
  border-radius: 1.5rem;
  cursor: pointer;
  text-align: center;
  background-color: #f9f9f9;
  transition: transform 0.4s ease;
}

.pollOption:hover {
  transform: scale(1.025);
  border: #2196f3 solid 1px;
}

.pollOptionVoted {
  cursor: not-allowed;
}

.selectedOption,
.nonSelectedOption {
  width: 90%;
  border-radius: 1.2rem;
  padding: 0.8rem 1rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.optionText {
  font-size: 1rem;
  font-weight: 500;
  color: #333;
}

.optionStats {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 8px;
}

.progressBar {
  height: 8px;
  background-color: #007bff;
  border-radius: 4px;
  transition: width 0.3s ease;
}

.percentage {
  font-size: 0.9rem;
  color: #666;
  font-weight: bold;
}

.communityContainer {
  width: 100% !important;
  display: flex;
  flex-direction: column;
}

.leftColumn,
.rightColumn {
  height: 100vh;
  overflow-y: scroll;
  padding-top: 1rem;
  position: sticky;
  top: 0; /* Makes the elements sticky at the top of the page */
}

.leftColumn::-webkit-scrollbar,
.centerColumn::-webkit-scrollbar,
.rightColumn::-webkit-scrollbar {
  display: none;
}

.centerColumn {
  border-radius: 0.7rem;
  margin-top: 0.5rem !important;
  border: 1px solid #ddd;
  padding: 0 !important;
}

.centerContent {
  padding-top: 1.2rem;
  max-width: 100%;
  border-radius: 8px;
}

.repliesTab {
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 1.5rem;
  margin-left: 1rem;
}

.postDetailLeftWrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.editPostContainer {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.editPostContainer h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.editPostContainer textarea {
  width: 100%;
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
  resize: vertical;
}

.editPostContainer input[type="file"] {
  margin-bottom: 15px;
}

.imagePreviewContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 15px;
}

.imagePreview {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.editPostContainer input[type="text"] {
  width: 100%;
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
}

.editPostContainer button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.editPostContainer button:hover {
  background-color: #0056b3;
}

/* For mobile view, hide the left menu by default */
@media (max-width: 991px) {
  .leftColumn {
    position: absolute;
    display: none;
    top: 5;
    left: -250px;
    height: 100%;
    background-color: #f8f9fa;
    transition: left 0.3s ease;
    z-index: -1000;
  }

  .bullishButton {
    font-size: 0.65rem !important ;
    padding: 0.1rem 0.5rem !important;
  }

  .bearishButton {
    font-size: 0.65rem !important ;
    padding: 0.1rem 0.5rem !important;
  }

  .leftColumn.open {
    left: 0;
  }

  .rightColumn {
    position: absolute;
    display: none;
    top: -100px;
    right: -250px;
    height: 100%;
    background-color: #f8f9fa;
    transition: left 0.3s ease;
    z-index: 1000;
  }

  .menuToggleBtn {
    position: relative;
    margin-top: 0.4rem;
    background: none;
    border: none;
    font-size: 30px;
    cursor: pointer;
    z-index: 1050;
    margin-right: 1rem;
  }

  .followButton {
    padding: 0.3rem 0.6rem;
    font-size: 0.8rem;
    border-radius: 5px;
  }

  .profileImg {
    width: 30;
    height: 4rem;
    margin-left: 0.8rem;
  }

  .tabContent {
    padding: 0.5rem ;
    border-top: none;
  }
}

.imageGrid {
  display: flex;
  flex-wrap: wrap;
  gap: 0.6rem;
  justify-content: center;
}

.gridImageWrapper {
  flex: 1 0 calc(50% - 1rem);
  max-width: calc(50% - 1rem);
  height: 40vh;
}

.gridImage {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image fits properly */
  cursor: pointer;
  border-radius: 0.5rem; /* Optional: Adds rounded corners */
  transition: transform 0.5s;
}

.gridImage:hover {
  transform: scale(1.02); /* Hover effect */
}

/* Single image wrapper */
.singleImageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90% !important;
  height: 30vh;
}

.singleImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: transform 0.5s;
}
.singleImage:hover {
  transform: scale(1.02);
}

.transparentModal {
  background-color: transparent !important;
  border: none;
}

.modalBody {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalImage {
  /* max-height: 80vh; */
  width: 100%;
  max-width: 120%;
  margin: auto;
}

.prevButton,
.nextButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  color: white;
  border: none;
  font-size: 1.5rem;
  padding: 0.5rem 1rem;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10;
}

.prevButton {
  left: 1rem;
}

.nextButton {
  right: 1rem;
}

.prevButton:hover,
.nextButton:hover {
  background-color: white;
  color: black;
}

@media (max-width: 586px) {
  .leftColumn,
  .rightColumn {
    display: none;
  }
  .centerColumn {
    padding: 0.3rem !important;
  }

  .centerContent {
    padding: 0rem;
  }
}

/* FollowingFollower */

.userContainer {
  display: flex;
  align-items: center;
  justify-content: start;
}

.userimageContainer {
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
}

.usernameContainer {
  display: flex;
  flex-direction: column;
  padding-left: 1.5rem; /* Stack name and username vertically */
}

.userfollowButton {
  margin-left: auto;
  margin-right: 2rem; /* Ensure the button stays at the far right */
}

/* Load More Effect */

.blueLightLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  margin-top: 10px;
  overflow: hidden;
  position: relative;
}

.blueLight {
  width: 150px;
  height: 15px;
  background: linear-gradient(
    to right,
    rgba(0, 123, 255, 0.2),
    rgba(0, 123, 255, 1),
    rgba(0, 123, 255, 0.2)
  );
  border-radius: 50px;
  animation: lightMove 2s infinite ease-in-out;
}

.searchContainer {
  position: relative;
}

.searchForm {
  position: relative;
  width: 93%;
}

.searchIcon {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.searchInput {
  width: 100%;
  padding: 0.4rem 0.2rem 0.4rem 2rem; 
  font-size: 0.8rem;
}

.secondsearchIcon{
  position: absolute;
  font-size: 0.75rem;
  right: 15px;
  top: 50%;
  border-radius: 0.2rem;
  padding:0.2rem 0.4rem;
  transform: translateY(-50%);
  background-color: rgb(199, 199, 199);
}

.searchResultsDropdown {
  max-height: 90vh;
  width: 90%;
  overflow-y: auto;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 100%;
  /* left: 0; */
  /* right: 0; */
  z-index: 999;
}

.resultType {
  font-size: 0.7rem;
  font-weight: 400;
  gap: 10px; 
}

.underline {
  flex-grow: 1; 
  height: 2px; 
  background-color: #d4d5d6; 
}

.resultItem {
  font-size: 0.9rem;
  padding: 0.7rem 0;
}

.alignRight{
  font-size: 0.7rem;
  text-align: right;
}

.commentDropdown {
  position: relative;
}
.dropdownIconWrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.dropdownMenu {
  position: absolute;
  top: 1.5rem;
  right: 0.5rem;
  background-color: #fff;
  z-index: 1;
}


@keyframes lightMove {
  0% {
    transform: translateX(-100%);
    opacity: 0.6;
  }
  50% {
    transform: translateX(0%);
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    opacity: 0.6;
  }
}

@media (max-width: 768px) {
  .feedFollowingWrapper {
    flex-direction: row;
    align-items: start;
  }

  .followButton {
    margin: 0 auto; /* Center the button */
  }
}
