@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  margin: 0;
  padding: 0;
  background-color: white !important;
  color: #333;
  font-family: "Poppins", sans-serif !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.8;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --text-color: #808080;
  --font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  color: #000;
  margin: 0;
}

h1 {
  font-size: 1.5rem;
  line-height: 2rem;
}

h2 {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

h3 {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

h4 {
  font-size: 1rem;
  line-height: 1.5rem;
}

h5 {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

h6 {
  font-size: 0.75rem;
  line-height: 1rem;
}
p {
  font-size: 1rem;
  line-height: 1.5;
  color: #333;
  margin: 0 0 16px 0;
  text-align: left;
  word-wrap: break-word;
}

.text11 {
  color: var(--text-color);
  font-size: 11px;
}

.news-title {
  color: var(--text-color);
  font-size: var(--font-size);
  margin-bottom: 4px;
  line-height: 1;
}

.news-status-btn {
  text-decoration: none;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.custom-link {
  color: #333;
  font-weight: bold;
  cursor: pointer;
}

.custom-link:hover {
  color: #007bff;
}

.container {
  width: 100%;
  max-width: 1920px !important;
  padding-right: 0.2rem !important;
  padding-left: 0.2rem !important;
  margin-right: auto;
  margin-left: auto;
}

.min-vh-100 {
  min-height: 100vh;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-navbar {
  padding-left: 2rem;
}

.nav-links .nav-link {
  margin-right: 1rem;
}

.nav-link:hover {
  border-radius: 0.5rem;
  background-color: white;
}

.sort-icon {
  font-size: 1em;
  color: gray;
}

.nav-link.custom {
  margin-right: 1rem;
}

.nav-link.custom:hover {
  background-color: transparent;
  color: #6329cb !important;
}

.nav-dropdown-custom > .nav-link {
  margin-right: 1rem;
}

.nav-dropdown-custom > .nav-link:hover {
  background-color: transparent;
  color: #6329cb !important;
}

.navbar-collapse.show,
.collapsing {
  position: absolute;
  top: 4rem;
  right: 0;
  margin-right: 0.5rem;
  border-radius: 1rem;
  z-index: 1000;
  background-color: white;
  box-shadow: 0 4px 8px rgba(255, 255, 0, 0.1);
  padding: 10px;
}

.handbugger.collapsed {
  position: relative;
  margin-right: 0.5rem;
  right: 0;
  border: none !important;
  background: transparent;
  outline: none !important;
  z-index: 1;
  cursor: pointer;
  display: block;
}

.handbugger.active {
  background-color: red;
}

.breadCrumb-custom {
  font-size: 0.8rem;
}

.breadCrumb-custom a {
  text-decoration: none;
  color: inherit;
}

.App {
  text-align: center;
  font-family: "Poppins", sans-serif !important;
}

.App-header {
  background-color: #e9ecef;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  animation: App-logo-spin infinite 20s linear;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-link {
  color: #61dafb;
}

/* Footer */
.footer {
  padding-top: 2.5rem;
  padding-bottom: 0.5rem;
  background-color: #e9ecef;
}

.footer-linebg {
  height: 0.4rem;
  background: linear-gradient(
    90deg,
    rgba(30, 48, 243, 1) 0%,
    rgba(226, 30, 128, 1) 100%
  );
}

.footer-logo {
  width: 9rem;
  margin-bottom: 1rem;
}

.footer-text {
  font-size: 1rem;
  margin-bottom: 1rem;
  font-weight: 500;
}

.footer-heading {
  font-weight: 550;
  margin-bottom: 0.8rem;
}

.footer-links .footer-link {
  font-size: 0.8rem;
  margin-bottom: 0.2rem;
  padding: 0;
  background-color: transparent;
  text-shadow: 1px 1px #fff;
}

.footer-link:hover {
  color: #6329cb !important;
  padding-left: 5px;
}

.footer-icon {
  font-size: 1rem;
}

.footer-copy,
.footer-dev {
  font-size: 0.75rem;
  margin: 0;
  color: #333;
}

.footer-dev > a {
  text-decoration: none;
  color: #333;
}

.no-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Edge */
}

.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .topbar-link {
    font-size: 19rem;
  }
}

@media (max-width: 992px) {
  .topbar-link {
    font-size: 0.8rem;
  }
}

@media (max-width: 768px) {
  .topbar-link {
    font-size: 0.7rem;
  }
}

@media (max-width: 576px) {
  .topbar-link {
    font-size: 0.6rem;
  }
}

.CircularProgressbar-text {
  font-size: 0.6rem !important;
}
